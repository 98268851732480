exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-design-tsx": () => import("./../../../src/pages/design.tsx" /* webpackChunkName: "component---src-pages-design-tsx" */),
  "component---src-pages-gallery-[id]-tsx": () => import("./../../../src/pages/gallery/[id].tsx" /* webpackChunkName: "component---src-pages-gallery-[id]-tsx" */),
  "component---src-pages-highlights-tsx": () => import("./../../../src/pages/highlights.tsx" /* webpackChunkName: "component---src-pages-highlights-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("./../../../src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */),
  "component---src-pages-preferredlender-tsx": () => import("./../../../src/pages/preferredlender.tsx" /* webpackChunkName: "component---src-pages-preferredlender-tsx" */),
  "component---src-pages-process-tsx": () => import("./../../../src/pages/process.tsx" /* webpackChunkName: "component---src-pages-process-tsx" */),
  "component---src-pages-subcontractor-tsx": () => import("./../../../src/pages/subcontractor.tsx" /* webpackChunkName: "component---src-pages-subcontractor-tsx" */)
}

